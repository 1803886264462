//
//
//
//
//
//

export default {
  name: 'ElButton',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
