//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'AlgLoginButton',
  computed: {
    ...mapGetters({
      isWorldFranchise: 'landings/isWorldFranchise',
    }),
  },
  methods: {
    getUrl() {
      if (!this.isWorldFranchise) {
        return 'https://learn.algoritmika.org'
      }

      return 'https://learn.alg.academy'
    },
  },
}
