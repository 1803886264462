//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AlgWhatsupIcon',
  computed: {
    url() {
      return this.$getTextMainSetting('ws_url')
    },
  },
}
