//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CityList from '@/components/common/CityList'
import ElButton from '@/components/UI/el-button'
import ElLink from '@/components/UI/el-link'
import debounce from '@/utils/debounce'
import FacultyMixin from '@/mixins/faculty'
import scroll from '@/mixins/scroll'
import eventBus from '@/utils/eventBus'
import { BRANCH_MOSCOW } from '@/const/branch'
import { mapState, mapGetters } from 'vuex'
import { LOCALE_CODE_RUSSIAN } from '@/const/locale'
import AlgWhatsupIcon from '@/components/common/icons/AlgWhatsupIcon'
import AlgTelegramIcon from '@/components/common/icons/AlgTelegramIcon'
import AlgLoginButton from '@/components/common/header/AlgLoginButton'

export default {
  name: 'PartnerHeader',
  components: {
    AlgLoginButton,
    AlgTelegramIcon,
    AlgWhatsupIcon,
    CityList,
    ElButton,
    ElLink,
  },
  mixins: [FacultyMixin, scroll],
  data() {
    return {
      showMobileMenu: false,
      isFixed: false,
      scrollPosition: 0,
      isLangSelectorActive: false,
    }
  },
  computed: {
    ...mapGetters({
      availableLanguageCodes: 'landings/availableLanguageCodes',
      currentLanguageCode: 'landings/currentLanguageCode',
    }),
    ...mapState(['i18n']),
    isCodingEnabled() {
      return this.isFacultyEnabled('coding') || false
    },
    isMathEnabled() {
      return (
        (this.isFacultyEnabled('math') &&
          this.$getTextMainSetting('branchLanguageCode') ===
            LOCALE_CODE_RUSSIAN) ||
        false
      )
    },
    isBootcampEnabled() {
      return this.$getTextMainSetting('branch_id') === BRANCH_MOSCOW
    },
  },
  mounted() {
    this.debounceScroll = debounce(this.scroll, 40)
    window.addEventListener('scroll', this.debounceScroll, { passive: true })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.debounceScroll)
  },
  methods: {
    activeClass(languageCode) {
      return this.isCurrentLanguage(languageCode)
        ? '_active disable-lang-chose'
        : ''
    },
    isCurrentLanguage(languageCode) {
      return languageCode === this.currentLanguageCode.substr(0, 2)
    },
    switchLanguage(languageCode) {
      if (this.isCurrentLanguage(languageCode)) {
        return
      }
      window.location.href = this.switchLocalePath(languageCode)
    },
    getLogo() {
      if (this.$i18n.locale === 'ru') {
        return require(`~/assets/img/partner/main/header/logo-ru.svg`)
      }
      return require(`~/assets/img/partner/main/header/logo-en.png`)
    },
    getLogoLabel() {
      if (this.$i18n.locale === 'ru') {
        return 'Международная школа программирования и математики'
        // return require(`~/assets/img/partner/main/header/logo-label-ru.svg`)
      }
      return 'International school of programming'
      // return require(`~/assets/img/partner/main/header/logo-label-en.svg`)
    },
    toggleMobileMenu() {
      if (this.showMobileMenu) {
        document.body.classList.remove('stop-scrolling')
        this.showMobileMenu = false
        document.documentElement.style.scrollBehavior = 'auto'
        window.scrollTo(0, this.scrollPosition)
        document.documentElement.style.scrollBehavior = 'smooth'
      } else {
        this.scrollPosition = window.scrollY
        document.body.classList.add('stop-scrolling')
        this.showMobileMenu = true
      }
    },
    scroll() {
      const headerHeight = document
        .getElementsByClassName('header')[0]
        .getBoundingClientRect().height
      this.isFixed = window.pageYOffset > headerHeight
    },
    onSignupButtonClick() {
      eventBus.$emit('click-signup-button')
      this.smoothVerticalScrolling('#signup', 'main')
    },
    onClickPhoneNumber() {
      eventBus.$emit('click-phone-number')
    },
  },
}
